body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.slick-slide img {
  margin: auto;
  
}

/* .unit-pic {
	position: relative;
	display: inline-block;
}

.unit-pic .edit {
	display: block;
}

.edit {
	padding-top: 7px;	
	padding-right: 7px;
	position: absolute;
	right: 0;
	top: 0;
	display: none;
}

.edit a {
	color: #000;
} */

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.unit-pic:hover .image {
  opacity: 0.3;
}

.unit-pic:hover .middle {
  opacity: 1;
}

.text {
  /* background-color: #04AA6D; */
  /* color: white; */
  font-size: 16px;
  padding: 16px 32px;
}

.main-signin-wrapper .back-img{
  filter: blur(2px);
 /* background-position: 'center !important'; */
  /* background-image:url('https://cdn.thecoolist.com/wp-content/uploads/2022/01/Types-of-Houses.png'); */
  background-repeat: 'no-repeat !important';
  background-size:'cover !important'; 
}